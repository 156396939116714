import { useEffect, useState } from 'react'
import { Box } from '@mui/material'
import { withAuthenticationRequired } from '@auth0/auth0-react'
import Layout from 'manage-tritag/components/layout'
import EnhancedTable from 'manage-tritag/components/table/enhanced-table'
import EnhancedTableFilter from 'manage-tritag/components/table/enhanced-table/enhanced-table-filter'
import {
  QueryParams,
  Team,
  useLazyGetTeamsQuery,
} from 'manage-tritag/services/api/endpoints/teams'
import Spinner from 'manage-tritag/components/loading/spinner'

interface HeadCell {
  disablePadding: boolean
  id: keyof Team
  label: string
  numeric: boolean
}

const TeamPage = () => {
  const [params, setParams] = useState<QueryParams>({
    offset: 0,
    limit: 100000,
    search: '',
    currentSeason: true,
  })
  const [trigger, result] = useLazyGetTeamsQuery()

  const { isLoading, data: teams } = result

  useEffect(() => {
    trigger(params)
  }, [params])

  const headCells: HeadCell[] = [
    {
      id: 'ttr_id',
      numeric: false,
      disablePadding: false,
      label: 'ID',
    },
    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      label: 'Name',
    },
    {
      id: 'association',
      numeric: false,
      disablePadding: false,
      label: 'Association',
    },
  ]
  const bodyCells = ['ttr_id', 'name', 'association']
  return (
    <Layout label1="Teams" url="/teams">
      {isLoading ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: 'calc(100vh - 145px);',
          }}
        >
          <Spinner size={40} color="#008174" />
        </Box>
      ) : (
        teams && (
          <>
            <EnhancedTableFilter params={params} setParams={setParams} />
            <EnhancedTable
              tableTitle="Teams"
              uniqueIdentifier="ttr_id"
              rows={teams.map((team, index) => ({
                ...team,
                id: index,
                association: team.association ? team.association.name : 'None',
              }))}
              headCells={headCells}
              bodyCells={bodyCells}
            />
          </>
        )
      )}
    </Layout>
  )
}

export default withAuthenticationRequired(TeamPage)
